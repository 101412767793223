/* You can add global styles to this file, and also import other style files */


/* Importing Bootstrap SCSS file. */
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Sarabun&display=swap');

@import "~angular-notifier/styles";

body , a{
    font-family: 'Sarabun', sans-serif !important;
    font-weight: 100;
    font-size: 15px;
}

a {
    color: #495057;
}
.logo-img{
  color: #4F6DA7;
  cursor: pointer;
}
.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(179, 179, 179);
    opacity: 1;
    /* Firefox */
}

.nav-thai-font {
    font-size: 14px;
}

.nav-eng-font {
    font-size: 16px;
}

.topicText {
    color: #4F6DA7;
    margin-bottom: 2em;
    margin-top: 1em;
    padding-top: 2em;
}

.nav-link-text {
    color: #495057;
}

.margin-nav {
    margin-left: 20px;
    margin-right: 20px;
}

.btn-outline-primary {
    color: #495057;
    background-color: #fff;
    border-color: #ced4da;
    /*set the color you want here*/
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.open>.dropdown-toggle.btn-outline-primary {
    color: #495057;
    background-color: #bfe8f0;
    border-color: #ced4da;
    /*set the color you want here*/
}

// label sign-up, sign-in
.label-inline {
    display: flex;
    float: left;
}

.label-margin {
    margin-left: 15px;
}

.home-icon {
    color: #4F6DA7;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-end;
    margin-right: 3%;
}

// sign in & sign up pages
// set thai input format
.register-container {
    padding-top: 2em;
    padding-bottom: 2em;
    overflow: hidden;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.rightSide {
    float: right;
}

.left {
    float: left;
}

.sameLine {
    display: inline-block;
}

.topic-to-left {
    display: block;
    text-align: start;
}

.table-text-start {
    text-align: start;
}

.header-thai-font {
    color: #6b80ac;
    font-size: 15px;
}

.setMargin {
    margin-left: 15px;
    margin-right: 15px;
}

.btn-regis1 {
    border-radius: 12px;
    width: 150px;
    background-color: #4F6DA7;
    border-color: #4F6DA7;
}

.btn-regis2 {
    border-radius: 12px;
    width: 150px;
    background-color: #7e8288;
    border-color: #7e8288;
}

// style of input form
.inputForm {
    width: 95%;
    height: auto;
    margin: auto;
    padding: 20px;
    margin-top: 45px;
}

.head-contrainer {
    margin-right: 7%;
    margin-left: 7%;
}

.box-contrainer {
    display: flex;
}

.box-inner {
    display: inline;
}

// set margin of sub header
.mg-top-left {
    margin-top: 61px;
    margin-left: 26px;
}

// user input form header
.header-mg {
    // tc = text color
    margin-top: 1em;
    margin-right: 1.5em;
}

.margin-text-head {
    margin-top: 12%;
}
.margin-text-head-8-grid {
    margin-top: 6%;
}

// header for admin
.mg-top-right-header {
    margin-top: 8%;
}

.margin-text-sub {
    margin-top: 10px;
}

.mg-top {
    margin-top: 38px;
}

@media only screen and (max-width: 500px) {
    .mg-top {
        margin-top: 0;
    }
}

// set input form to center
.div-center {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.header-tc {
    color: #4F6DA7;
}

.mg-checkbox {
    margin-left: -4%;
    margin-top: 20px;
}

.title-header {
    color: #6b80ac;
}

.marginContainer {
    margin-right: 7%;
    margin-left: 7%;
    padding-top: 2em;
    padding-bottom: 2em;
}

.textColor {
    color: #6b80ac;
}


.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #565f67;
}

.sizeLogo {
    width: 2.8rem;
    height: auto;
    vertical-align: middle;
    padding-top: 3px;
    margin-bottom: 1em;
}

// background light grey
.box-color {
    background-color: #EDEDED;
    border-radius: 1em;
    height: auto;
}

.set-gap-box {
    margin: 2em;
}

.cuvreBott {
    border-radius: 8px;
    width: 120px;
    // margin-top: 20px;
    // margin: auto;
    background-color: #4F6DA7;
    border-color: #4F6DA7;
    // margin-top: 20px;
}

.more-button {
    color: white;
    width: 120px;
    border-radius: 8px;
    margin-right: 10px;
}

.submit-btn {
    height: 40px;
    margin-top: 10px;
    background-color: #788db8;
    border: 1px solid #788db8;
}

.cancle-btn {
    height: 40px;
    margin-top: 10px;
    background-color: #ababab;
    border: 1px solid #ababab;
}

.add-btn {
    background-color: #2c2c2c;
    border: 1px solid #2c2c2c;
}

.delete-btn {
    background-color: #ababab;
    border: 1px solid #ababab;
}

.plus-btn {
    color: white;
    border-radius: 5px;
    background-color: #ababab;
    margin-left: 10px;
    border: 1px solid #ababab;
}

.top-section {
    margin-top: 20px;
    margin-bottom: 20px;
}

.gap-comfirm-checkbox {
    margin-bottom: 10px;
    margin-top: 50px;
}

.gap-between-button {
    // margin-right: 20px;
    margin-bottom: 20px;
}

.thead-background-color {
    background-color: #F0F0FA;
}

.sent-icon {
    margin-right: 8px;
}

.sreach-button {
    width: 300px;
    margin-right: 10px;
}

.float-right {
    // display: flex;
    // float: right;
    margin-bottom: 20px;
}

.btn-div {
    margin-top: 30px;
}

// .submit-btn{
//   margin-top: 10px;
// }
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 2px solid #6b80ac;
}

// alert
// input:focus, input::after{
//   outline: none;
// select
select {
    // width: 150px;
    // height: 30px;
    // padding: 5px;
    color: rgb(179, 179, 179); // !important;
}

select:invalid {
    color: rgb(179, 179, 179);
}

select:valid {
    color: #495057;
}

select option {
    color: #495057;
}

select option:first-child {
    color: rgb(179, 179, 179);
}

option:after {
    content: " ";
    height: 5px;
    width: 5px;
    background: #c00;
    border-radius: 5px;
    display: inline-block;
}

// }
.text-err {
    color: red;
    float: left;
    margin-top: -22px;
}

.margin-text-err {
    margin-left: 1em;
}

.alert-error {
    border-color: red;
}

input.ng-invalid.ng-dirty {
    border-color: red !important;
    // box-shadow: 0 1px 0 0 red !important;
}

input.ng-untouched {
    border-color: #ced4da;
}

// style for home page
.text-size-content {
    font-size: 18px;
}

.border-content {
    margin-top: 2em;
    margin-bottom: 2em;
}

.gap-header-and-content {
    margin: 2em;
}

// the-nav-bar
.logo-div {
    width: 25%;
    // margin-top: 13px;
}

.logo-img {
    // margin-top: 6px;
    // width: 2.7em;
    // height: 2.7em;
    margin-top: 15px;
    width: 2.7em;
    height: 2.7em;
    margin-right: -28px;
    margin-left: 10px;
}

.logo-font-div {
    margin-left: 13px;
    margin-top: 10px;
}

.sub-font {
    margin-top: -15px;
    font-size: 11px;
}

// nav button style
.transparent-button {
    border-color: transparent;
    background-color: transparent;
}

// .transparent-button:hover{
//   border-color: red;
//   background-color: red;
// }
.nav-item:hover {
    background-color: #657eb1;
    // border-radius: 5px;
}
.border-rad:hover{
  border-radius: 8px;
}

.li-box:hover .a-text {
    color: white !important;
}

.padding-li {
    padding-left: 5px;
    padding-right: 5px;
}

sign-in-btn {
    background-color: black;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #4F6DA7;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}
.nav-link-tab:hover{
  background-color: #fff;
}
// dropdown for navbar
.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    background-color: white;
    color: black;
    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 16px;
    font-size: 16px;
    border: none;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    width: 350px;
    color: black;
    padding: 8px 27px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #657eb1;
    color: white;
}

@media only screen and (max-width: 1300px) {
    .dropdown:hover .dropdown-content {
        display: block;
        height: 250px;
    }
    .dropdown:hover .dropbtn {
        background-color: #657eb1;
        color: white;
        width: 100%;
    }
    .dropdown:hover .dropdown-content {
        display: block;
        height: 250px;
        // width: 100%;
    }
}

// sign-in botton
.btn-dark:hover {
    background-color: #5e6367 ;
    border-color: #5e6367 ;
}
.btn-blue{
    background-color: #657eb1 !important;
    border-color: #657eb1;
}
.btn-blue:hover {
    background-color: #486bb3;
    border-color: #486bb3;
}

.margin-auto{
  margin: auto
}

// mose pointer
.pointer {
    cursor: pointer;
}

//vertical scrollbar menu
.vertical-menu {
    width: 350px;
    // height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

// set service to center
.text-align-center {
    text-align: center;
}

.text-align-start {
    text-align: start;
}

.text-align-end {
    text-align: end;
}

// certificate page
.language-btn {
    height: 35px;
    width: 90px;
    margin-right: 7%;
    border-radius: 8px;
    color: #fff;
    background-color: #85b3e4;
    border-color: #89c2ff;
    margin-top: 11px;
}

.language-btn:hover {
    color: #fff;
    background-color: #245890;
    border-color: #245890;
}

// hamburger , navbar
.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 0;
    align-items: center;
}

.fa-1x {
    font-size: 1.5rem;
}

.navbar-toggler.toggler-example {
    cursor: pointer;
}

.sticky-div {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.open>.dropdown-toggle.btn-outline-primary {
    background-color: white;
    border-color: transparent;
    color: #495057;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    background-color: white;
    border-color: transparent;
    color: #495057;
}

.p-3 {
    padding: 0.5em !important;
}

// .btn:focus, .btn.focus{
//   box-shadow: 0 0 0 0.2rem transparent;
// }
.dropdown-menu {
    border: 1px solid rgb(255 255 255 / 15%);
    background-color: #F9FAFA;
}

.footer-bg {
    background-color: #e6e6e6;
    height: auto;
}

// footer
.sub-text {
    font-size: 13px;
}

.logo-img-footer {
    margin-top: 6px;
    width: 2.7em;
    height: 2.7em;
}

.center-horizontall {
    text-align: center;
}

.center-vertical {
    margin: auto;
    text-align: center;
    height: 300px;
    position: relative;
}

.inner-vertical {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

// map style
.img-map {
    width: 300px;
    margin-bottom: 30px;
}

.wrap {
    display: flex;
    flex-wrap: wrap;
}

// admin zone
.status-btn {
    color: white;
    width: 90px;
    border-radius: 8px;
}

.new-btn {
    background-color: #ec971f;
    border: 1px solid #ec971f;
}

.pending-btn,
.set-date-btn {
    background-color: #31b0d5;
    border: 1px solid #31b0d5;
}

.inprogress-btn,
.apply-btn,
.on-date-btn {
    background-color: #449d44;
    border: 1px solid #449d44;
}

.finised-btn {
    background-color: #2e6da4;
    border: 1px solid #2e6da4;
}

.cancelled-btn {
    background-color: rgb(162, 162, 162);
    border: 1px solid rgb(162, 162, 162);
}

// laboratory job detail
.text-data {
    color: grey;
}

.checkbox-text {
    color: rgb(79, 79, 79);
}

.hr-admin {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid grey;
    // width: 74%;
}
.hr-headline {
    margin-top: 0rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid grey;
    // width: 74%;
}
.head-highlight{
  width: 100%;
  color: white;
  background-color: #4f6da7;
}

.hr-part-section {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #6b80ac;
    // width: 74%;
}

.detail-header {
    margin-left: 12.5%;
}

// .attach-file-add-btn{
//   // margin-top: 40px;
//   // margin-top: -10px;
// }
.more-button {
    color: white;
    width: 120px;
    border-radius: 8px;
    margin-right: 30px;
    height: 40px;
    // margin-top: 10px;
}

.save-btn {
    background-color: #788db8;
    border: 1px solid #788db8;
}

.discard-btn,
.finish-date-btn {
    background-color: rgb(162, 162, 162);
    border: 1px solid rgb(162, 162, 162);
}

.edit-btn {
    background-color: black;
    border: 1px solid black;
}

//operations button
.end-date-btn {
    background-color: #c9302c;
    border: 1px solid #c9302c;
}

// th{
//   text-align: center;
// }
table {
    font-size: 15px;
    text-align: center;
}

.table-left {
    font-size: 15px;
}

td {
    font-size: 14px;
}

th {
    vertical-align: text-top !important;
}

.more-detail-position {
    text-align: end;
    // margin-bottom: 2em;
}

.search-input {
    width: 20%;
    align-self: flex-end;
}

.space-between-div {
    display: flex;
    height: 100%;
    justify-content: space-between;
}

// job-lab
.margin-top-10px {
    margin-top: 10px;
}

.margin-top-20px {
    margin-top: 20px;
}
.margin-top-50px {
    margin-top: 50px;
}

// lab review page, model section
.mg-subsection-20px {
    margin-left: 20px;
    margin-right: 20px;
}

// task page-->activity button
.btn-blue-basic {
    color: #fff !important;
    background-color: #245890 !important;
    border-color: #245890 !important;
    width: 140px;
    margin-bottom: 5px;
}

.btn-blue-basic:hover {
    color: #fff !important;
    background-color: #5078a2 !important;
    border-color: #5078a2 !important;
}

// submission-->link button
.btn-dark-blue-basic {
    color: #fff;
    background-color: #4b81bb;
    border-color: #4b81bb;
    width: 180px;
    margin-bottom: 5px;
}

.btn-dark-blue-basic:hover {
    color: #fff;
    background-color: #245890;
    border-color: #245890;
}

.btn-payment-status {
    color: #fff;
    background-color: rgb(28, 69, 135);
    border-color: rgb(28, 69, 135);
    width: 180px;
    margin-bottom: 5px;
}

.btn-payment-status:hover {
    color: #fff;
    background-color: rgb(11, 43, 94);
    border-color: rgb(11, 43, 94);
}

.btn-start {
    color: #fff;
    background-color: #3370ec;
    border-color: #3370ec;
    width: 180px;
    margin-bottom: 5px;
}

.btn-start:hover {
    color: #fff;
    background-color: #0554f3;
    border-color: #0554f3;
}

.mg-bottom-10px {
    margin-bottom: 10px;
}

// input in table
.transparent-input {
    height: 44px;
    width: 180px;
    border-color: transparent;
    text-align: center;
}

.transparent-input:focus-visible {
    height: 44px;
    border-color: transparent;
}

// table setvertical to center
.vertical-center {
    vertical-align: middle;
}

.search-btn {
    position: absolute;
    right: 8%;
}

.search-btn-equip {
    position: relative;
    right: 8%;
}

.test-btn {
    position: relative;
    left: 5%;
}

.width-250 {
    width: 250px;
}

ul.no-bullets {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
// calendar
.icon-color, .text-color-white{
  color: white;
}
.icon-color:hover{
  color: rgb(194, 194, 194);
}
.nav{
  flex-wrap:nowrap;
}

// header service
.background-blue{
  background-color: #788db8;
}

.font-size-22{
  font-size: 22px;
}
.font-size-18{
  font-size: 18px;
}
.font-size-15rem{
  font-size: 1.5rem;
}
.text-align-right{
  text-align: right;
}
.margin-top-7px{
  margin-top: 7px;
}
.margin-top-28px{
  margin-top: 28px;
}
.display-grid{
  display: grid;
}
.add-width{
  width: 210px;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
.none {
  display: none;
}

.cursor__point {
  cursor: pointer;
  color: blue;
}

.insert__image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
}
.ng-invalid {
  border: red 1px solid;
}

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  padding: 10px;
}


.btn__complete {
  color: #fff !important;
  background-color: #32CD32 !important;
}

.btn__complete:hover {
  color: #fff !important;
  background-color: #9ACD32 !important;
}
.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.fa-eye,
.fa-eye-slash {
      padding: 10px;
      margin-top: -60px;
      min-width: 50px;
      text-align: left;
      float: right;
}
.rad-banner {
  border-radius: 2em;
  width: 100%;
  display: block;
}
th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

th[sortable].desc:before,
th[sortable].asc:before {
  content: '';
  display: block;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC') no-repeat;
  background-size: 22px;
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -22px;
}

th[sortable].desc:before {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

/* Filtering table demo */
ngbd-table-filtering span.ngb-highlight {
  background-color: yellow;
}

/* Complete table demo */
ngbd-table-complete span.ngb-highlight {
  background-color: yellow;
}

ngb-carousel .picsum-img-wrapper {
  position: relative;
  height: 0;
  padding-top: 55%;
  /* Keep ratio for 900x500 images */
}

ngb-carousel .picsum-img-wrapper>img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.top__address label{
  margin-bottom: 0px !important;
}

  .pagination ,.pagination li{
    display: inline-block !important;
  }
.page-item{

    width: 52px !important;
    text-align: center;
}
.sticky-top{
    top: 121px;
    background-color: white;
    z-index: 20;
    box-shadow: inset 0 1px 0 #dee2e6, inset 0 -2px 0 #dee2e6;
}
